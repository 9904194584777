import * as React from "react"
import { center } from '../style/index'

import fbIcon from '../assets/fbIcon'
import igIcon from '../assets/igIcon'
import ytIcon from '../assets/ytIcon'


const socialMedia = {
  INSTAGRAM: {
    icon: igIcon,
    bgcColor: '#C13584',
  },
  FACEBOOK: {
    icon: fbIcon,
    bgcColor: '#3B5998',
  },
  YOUTUBE: {
    icon: ytIcon,
    bgcColor: '#FF0000',
  },
}

export default function SocialMedia({data}) {

  const getIcon = service => {
    return socialMedia[service]?.icon
  }
  const getBgcColor = service => {
    return socialMedia[service]?.bgcColor
  }

  return (
    <div css={style}>
          <a href="https://www.facebook.com/ModelowyOgrod" aria-label="socialMedia" target="_blank" rel="noreferrer" style={{ backgroundColor: "#3B5998" }}>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path></svg>
          </a>

<a  href="https://www.instagram.com/modelowyogrod_bojano?igsh=MXZlZHltZ3V2bnc2bg==" aria-label="socialMedia" target="_blank" rel="noreferrer" style={{ backgroundColor: "#C13584" }}>
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path fill="#000" d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"></path></svg>
  </a>
    </div>
  )
}


const style = {
  ...center,
  gap: '2rem',
    a: {
      ...center,

      width: '5rem',
      height: '5rem',

      borderRadius: '100%',

        svg: {
          width: '2.5rem',
          height: '2.5rem',

          filter: 'invert(1)',
        }
    }
}
